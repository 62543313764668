import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StartUP } from '../../models/app.models';

@Component({
  selector: 'app-company-age',
  templateUrl: './company-age.component.html',
  styleUrls: ['./company-age.component.scss']
})
export class CompanyAgeComponent implements OnInit {
  @Input()
  isStartUp: StartUP;

  @Output()
  companyAgeChanged = new EventEmitter<StartUP>();

  constructor() { }

  ngOnInit() {
  }

  setIsStartUp(companyAge: StartUP) {
    this.companyAgeChanged.emit(companyAge);
  }

  get startUp() {
    return StartUP;
  }
}
