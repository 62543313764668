import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: [ './range.component.scss' ]
})
export class RangeComponent implements OnInit {
  @HostListener('mousedown', [ '$event' ]) onMouseDown($event) {
    // if ($event.target.id === 'range-btn') {
    this.startMoving = true;
    this.startPosition = $event.screenX;
    // }
  }

  @HostListener('mouseup', [ '$event' ]) onMouseUp($event) {
    this.invokeMouseEventsFunc($event);
  }

  @HostListener('mousemove', [ '$event' ]) onMouseMove($event) {
    if (this.startPosition) {
      const delta = ($event.screenX - this.startPosition) / this.ref.nativeElement.clientWidth * 100;
      this.moveValue = this._value + delta;

      if (this.moveValue < 0) {
        this.moveValue = 0;
      }

      if (this.moveValue > 100) {
        this.moveValue = 100;
      }
      this.valueChange.emit(Math.floor(this.moveValue));
    }
  }

  @HostListener('mouseleave', [ '$event' ]) onMouseLeave($event) {
    this.invokeMouseEventsFunc($event);
  }

  private _value: number = 0;

  @Input() set value(value: number) {
    if (!this.startPosition) {
      this.moveValue = 0;
      this.startPosition = null;
      this._value = Number.isNaN(+value) ? 0 : +value;
      if (this._value < 0) {
        this._value = 0;
      }

      if (this._value > 100) {
        this._value = 100;
      }
    }
  }

  @Input()
  minValue = 0;

  @Input()
  maxValue = 100;

  @Output()
  valueChange = new EventEmitter<number>();

  private startMoving = false;
  private startPosition = null;
  private moveValue = null;

  constructor(
    private ref: ElementRef,
  ) {
  }

  ngOnInit() {
  }

  getValue() {
    return (this.moveValue ? this.moveValue : this._value) / ((this.maxValue - this.minValue) / 100);
  }

  get calcDelta() {
    return (1 - 30 / this.ref.nativeElement.clientWidth);
  }

  invokeMouseEventsFunc($event) {
    if (this.startPosition) {
      const delta = ($event.screenX - this.startPosition) / this.ref.nativeElement.clientWidth * 100;
      if (delta === 0) {
        const rect = $event.target.getBoundingClientRect();
        const x = $event.clientX - rect.left; //x position within the element.
        this._value = Math.round(x / this.ref.nativeElement.clientWidth * 100);
      } else {
        this._value += delta;
      }
      if (this._value < 0) {
        this._value = 0;
      }

      if (this._value > 100) {
        this._value = 100;
      }
      this.valueChange.emit(Math.floor(this._value));
      this.startPosition = null;
      this.moveValue = null;
    }
  }

}
