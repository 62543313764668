import { NgModule } from '@angular/core';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { RangeComponent } from './components/range/range.component';
import { InfoComponent } from './components/info/info.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SummaryChartComponent } from './components/summary-chart/summary-chart.component';
import { CompanyAgeComponent } from './components/company-age/company-age.component';
import { DataAccordionComponent } from './components/data-accordion/data-accordion.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [ InputNumberComponent, RangeComponent, InfoComponent, SummaryChartComponent, CompanyAgeComponent,
    DataAccordionComponent ],
  declarations: [ InputNumberComponent, RangeComponent, InfoComponent, SummaryChartComponent, CompanyAgeComponent,
    DataAccordionComponent ],
  providers: [ DecimalPipe ],
})
export class SharedModule {
}
