<div class="range-wrapper">
  <div class="range-title">
    Wachstum in Prozent
  </div>

  <div class="range-control">
    <div class="range-value" [style.width.%]="getValue() * calcDelta"></div>
    <div class="range-btn"
         id="range-btn"
         [style.left.%]="getValue() * calcDelta">
      <svg class="range-btn-img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="49" viewBox="0 0 48 49">
        <defs>
          <rect id="prefix__a" width="32" height="32" x="0" y="0" rx="16"/>
          <rect id="prefix__d" width="32" height="32" x="0" y="0" rx="16"/>
          <filter id="prefix__b" width="187.5%" height="187.5%" x="-43.8%" y="-31.2%" filterUnits="objectBoundingBox">
            <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(8 4.496)">
          <mask id="prefix__c" fill="#fff">
            <use xlink:href="#prefix__a"/>
          </mask>
          <use fill="#000" filter="url(#prefix__b)" xlink:href="#prefix__a"/>
          <use fill="#84B816" xlink:href="#prefix__a"/>
          <g fill="#1E3796" mask="url(#prefix__c)">
            <path d="M0 0H32V32H0z"/>
          </g>
          <mask id="prefix__e" fill="#fff">
            <use xlink:href="#prefix__d"/>
          </mask>
          <rect width="30" height="30" x="1" y="1" stroke="#FFF" stroke-width="2" rx="15"/>
          <g fill="#FFF" mask="url(#prefix__e)">
            <g>
              <path d="M4.702 0c.168-.001.333.055.471.162.285.203.369.623.186.94-.02.035-.043.067-.068.097L2.218 4.956l3.073 3.76c.23.274.217.705-.03.963-.027.027-.056.052-.088.075-.331.253-.78.204-1.06-.116L.667 5.418c-.221-.258-.221-.664 0-.922L4.114.277c.152-.18.366-.28.588-.277M10.462 0c-.168-.001-.333.055-.471.162-.285.203-.369.623-.186.94.02.035.043.067.068.097l3.073 3.757-3.073 3.76c-.23.274-.217.705.03.963.028.027.057.052.088.075.331.253.78.204 1.06-.116l3.447-4.22c.222-.258.222-.664 0-.922L11.05.277c-.152-.18-.366-.28-.588-.277" transform="translate(9 11)"/>
            </g>
          </g>
        </g>
      </svg>

    </div>
  </div>
</div>
