<ul class="accordion-list" [ngClass]="{'open': isOpen }">
  <li class="accordion-item" [ngClass]="{'always-show': isAlwaysShows(i)}" *ngFor="let item of data; let i = index">
    <div class="accordion-text-container">
      <h2 class="accordion-title">{{ item.title }}</h2>
    </div>
    <div class="accordion-value">€ {{ item.value | number : '1.2-2': 'de' }}</div>
    <div class="accordion-text">{{ item.text }}</div>
  </li>
</ul>

<div class="accordion-buttons">
  <a class="open-accordion-button" (click)="openAccordion()" *ngIf="!isOpen">
    <span>Entgelte der Zwischenmonate anzeigen</span>
  </a>
  <a class="close-accordion-button" (click)="closeAccordion()"  *ngIf="isOpen">
    <span>Entgelte der Zwischenmonate ausblenden</span>
  </a>
</div>
