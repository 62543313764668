<div class="input-wrapper">
  <button class="decrease-btn" [disabled]="isMinimalValue()"
          (mousedown)="decrease($event)" (mouseup)="stopDecrease()" (mouseleave)="stopDecrease()"></button>
  <div class="input-control-wrapper" [ngClass]="{'money': isMoney }">
    <input type="text" class="input-control" #inputElement
           [ngModel]="_value | number : '1.0' : 'de'"
           (input)="onValueChange($event.target.value)">
  </div>
  <button [disabled]="isMaximumValue()"
    (mousedown)="increase($event)" (mouseup)="stopIncrease()" (mouseleave)="stopIncrease()" class="increase-btn"></button>
</div>
