<div class="info">
  <svg class="open-svg" (click)="toggleInfo()" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
       viewBox="0 0 24 24">
    <path fill="#383838" fill-rule="evenodd"
          d="M12 3c4.97 0 9 4.029 9 9s-4.03 9-9 9-9-4.029-9-9 4.03-9 9-9zm0 1c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 7.358c.254 0 .459.205.459.458v4.768h1.925c.252 0 .457.204.457.457s-.204.46-.457.46H9.616c-.252 0-.457-.207-.457-.46s.204-.457.457-.457h1.925v-4.31H10.03c-.253 0-.458-.204-.458-.457 0-.254.205-.459.458-.459zM12 6.5c.935 0 1.696.762 1.696 1.696 0 .935-.761 1.697-1.696 1.697-.935 0-1.696-.761-1.696-1.697 0-.934.761-1.696 1.696-1.696zm0 .918c-.43 0-.779.35-.779.778 0 .43.349.78.779.78.43 0 .779-.35.779-.78 0-.429-.349-.779-.779-.779z"/>
  </svg>

  <div class="info-content" [ngClass]="{'reverse':isNotEnoughSpace,'hide':!isOpen}">
    <div class="icon-wrapper">
      <svg (click)="toggleInfo()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#383838" fill-rule="evenodd"
              d="M12 3c4.97 0 9 4.029 9 9s-4.03 9-9 9-9-4.029-9-9 4.03-9 9-9zm0 1c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 7.358c.254 0 .459.205.459.458v4.768h1.925c.252 0 .457.204.457.457s-.204.46-.457.46H9.616c-.252 0-.457-.207-.457-.46s.204-.457.457-.457h1.925v-4.31H10.03c-.253 0-.458-.204-.458-.457 0-.254.205-.459.458-.459zM12 6.5c.935 0 1.696.762 1.696 1.696 0 .935-.761 1.697-1.696 1.697-.935 0-1.696-.761-1.696-1.697 0-.934.761-1.696 1.696-1.696zm0 .918c-.43 0-.779.35-.779.778 0 .43.349.78.779.78.43 0 .779-.35.779-.78 0-.429-.349-.779-.779-.779z"/>
      </svg>
    </div>
    <div class="content-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
