import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StartUP, TabList } from './shared/models/app.models';
import { ChartModel } from './shared/models/chart.models';
import { debounce, isNil } from 'lodash';
import { DataAccordionModel } from './shared/components/data-accordion/data-accordion.model';
import { isMobile } from './shared/utils/device.utils';

const BASE_FEE = 9.90;
const MAX_TRANSACTION_COUNT = 1000000;
const MIN_TRANSACTION_COUNT = 0;
const MIN_REVENUE_COUNT = 0;
const MAX_REVENUE_COUNT = 50000000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'sme-price-calculator';

  activeTab: TabList;

  transactionCount: number = 0;
  revenueCount: number = 0;

  seasonLowTransactionNumber: number = 0;
  seasonLowRevenueNumber: number = 0;

  growPercentValue: number = 0;

  chartData: ChartModel[] = [];
  debounceFunc = debounce(() => {
    if (!this.isMobile()) {
      this.updateChartData();
    } else {
      this.updateAccordionData();
    }
  }, 500);
  isStartUp?: StartUP = null;

  get minRevenueCount() {
    return MIN_REVENUE_COUNT;
  }

  get maxRevenueCount() {
    return MAX_REVENUE_COUNT;
  }

  get maxTransactionCount() {
    return MAX_TRANSACTION_COUNT;
  }

  get minTransactionCount() {
    return MIN_TRANSACTION_COUNT;
  }

  @ViewChild('growPercentInput') growPercentInput: ElementRef;
  accordionData: DataAccordionModel[];

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // this.setActiveTab(TabList.grow);
    if(window.location === window.parent.location){
    this.addAdobeAnalyticsHeader(window.location.href);
  }
}

  setActiveTab(tabName: TabList) {
    this.activeTab = tabName;

    if (this.activeTab === TabList.grow) {
      if (!this.isMobile()) {
        this.updateChartData();
      } else {
        this.updateAccordionData();
      }
    }
  }

  setIsStartUp(value: StartUP) {
    this.isStartUp = value;
  }

  get tabList() {
    return TabList;
  }

  isTabActive(tabName: TabList) {
    return tabName === this.activeTab;
  }

  onGrowPercentValueChange($event: number) {
    this.growPercentValue = Number.isNaN(+$event) ? 0 : +$event;
    if (this.growPercentValue < 0) {
      this.growPercentValue = 0;
    }

    if (this.growPercentValue > 100) {
      this.growPercentValue = 100; // 100% max
    }
    this.growPercentInput.nativeElement.value = this.growPercentValue;
    this.debounceFunc();
  }

  private updateChartData() {
    const monthlyPercent = this.growPercentValue / 12;  // first month is 0
    const growTransactionPerMonth = this.transactionCount * monthlyPercent / 100;
    const growRevenuePerMonth = this.revenueCount * monthlyPercent / 100;
    let newChartData = [];
    let currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setMonth(currentDate.getMonth() + 1);

    let firstPoint = new Date(currentDate);
    firstPoint.setMonth(firstPoint.getMonth() - 1);

    newChartData.push({
      date: firstPoint,
      value: 0,
    });

    for (let i = 0; i < 13; i += 1) {
      newChartData.push({
        date: new Date(currentDate),
        value: (this.isStartUp === StartUP.yes ? 0 : BASE_FEE)
          + this.getRevenueFee(this.revenueCount + Math.round(growRevenuePerMonth * i))
          + this.getTransactionFee(this.transactionCount + Math.round(growTransactionPerMonth * i)),
      });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    this.chartData = newChartData;
    this.cdr.detectChanges();
  }

  onTransactionCountChanged($event: number) {
    this.transactionCount = $event;
    this.cdr.markForCheck();
    // console.log('actual trans count', this.transactionCount);
  }

  private getTransactionFee(transactions: number) {
    let transactionsLeft = transactions - 20; // 20 free
    let fee = 0;

    // for .45 cost
    if (transactionsLeft > 0) {
      if (transactionsLeft < 30) {
        fee += transactionsLeft * .45;
        transactionsLeft = 0;
      } else {
        fee += 30 * .45;
        transactionsLeft -= 30;
      }
    }

    // for .30 cost
    if (transactionsLeft > 0) {
      if (transactionsLeft < 50) {
        fee += transactionsLeft * .30;
        transactionsLeft = 0;
      } else {
        fee += 50 * .30;
        transactionsLeft -= 50;
      }
    }

    // for .15 cost
    if (transactionsLeft > 0) {
      fee += transactionsLeft * .15;
      transactionsLeft = 0;
    }


    return fee;
  }

  private getRevenueFee(revenue: number) {
    let fee = 0;
    let revenueLeft = revenue - 5000; // 5000 free

    // for 0,030%
    if (revenueLeft > 0) {
      if (revenueLeft < 20000) {
        fee += Math.round(revenueLeft * 0.030) / 100;
        revenueLeft = 0;
      } else {
        fee += Math.round(20000 * 0.030) / 100;
        revenueLeft -= 20000;
      }
    }

    // for 0,025%
    if (revenueLeft > 0) {
      if (revenueLeft < 50000) {
        fee += Math.round(revenueLeft * 0.025) / 100;
        revenueLeft = 0;
      } else {
        fee += Math.round(50000 * 0.025) / 100;
        revenueLeft -= 50000;
      }
    }

    // for 0,015%
    if (revenueLeft > 0) {
      fee += Math.round(revenueLeft * 0.015) / 100;
      revenueLeft = 0;
    }


    return fee;
  }

  getFeeValue() {
    return (this.isStartUp === StartUP.yes ? 0 : BASE_FEE)
      + this.getTransactionFee(this.transactionCount)
      + this.getRevenueFee(this.revenueCount);
  }

  onRevenueChanged($event: number) {
    this.revenueCount = $event;
  }

  getSeasonLowFee() {
    return (this.isStartUp === StartUP.yes ? 0 : BASE_FEE)
      + this.getTransactionFee(this.seasonLowTransactionNumber)
      + this.getRevenueFee(this.seasonLowRevenueNumber);
  }

  getSeasonHighFee() {
    return (this.isStartUp === StartUP.yes ? 0 : BASE_FEE)
      + this.getTransactionFee(this.transactionCount)
      + this.getRevenueFee(this.revenueCount);
  }

  onSeasonLowTransactionNumberChanged($event: number) {
    this.seasonLowTransactionNumber = $event;
  }

  onSeasonLowRevenueNumberChanged($event: number) {
    this.seasonLowRevenueNumber = $event;
  }

  onSeasonHighTransactionNumberChanged($event: number) {
    this.transactionCount = $event;
  }

  onSeasonHighRevenueNumberChanged($event: number) {
    this.revenueCount = $event;
  }

  onGrowTransactionNumberChanged($event: number) {
    this.transactionCount = $event;
    this.debounceFunc();
  }

  onGrowRevenueNumberChanged($event: number) {
    this.revenueCount = $event;
    this.debounceFunc();
  }

  onCompanyAgeChanged($event: StartUP) {
    this.isStartUp = $event;

    if (this.activeTab === TabList.grow) {
      this.debounceFunc();
    }
  }

  isCompanyAgeSelected() {
    return !isNil(this.isStartUp);
  }

  get startUp() {
    return StartUP;
  }

  getRevenueStep(value) {
    if (value >= 100000) {
      return 10000;
    } else if (value >= 50000) {
      return 5000;
    } else if (value >= 10000) {
      return 1000;
    } else {
      return 100;
    }
  }

  isMobile() {
    return isMobile();
  }

  private updateAccordionData() {
    this.accordionData = [];

    const monthlyPercent = this.growPercentValue / 12;  // first month is 0
    const growTransactionPerMonth = this.transactionCount * monthlyPercent / 100;
    const growRevenuePerMonth = this.revenueCount * monthlyPercent / 100;
    let currentDate = new Date();
    let newAccordionData = [];
    currentDate.setDate(1);
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setMonth(currentDate.getMonth() + 1);

    const getTransactionCount = (index: number) => {
      return this.transactionCount + Math.round(growTransactionPerMonth * index);
    }

    const getRevenueCount = (index: number) => {
      return this.revenueCount + Math.round(growRevenuePerMonth * index);
    }

    for (let i = 0; i < 12; i += 1) {
      newAccordionData.push({
        title: `${currentDate.toLocaleString('de', { month: 'long' })} ${currentDate.getFullYear()}`,
        text: `${getTransactionCount(i)} Transaktionen / € ${getRevenueCount(i)} Umsatz`,
        value: (this.isStartUp === StartUP.yes ? 0 : BASE_FEE)
          + this.getRevenueFee(getRevenueCount(i))
          + this.getTransactionFee(getTransactionCount(i)),
      } as DataAccordionModel);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    this.accordionData = newAccordionData;
    this.cdr.detectChanges();

  }

  getNavigationLink() {
    return this.isStartUp === StartUP.yes
      ? 'https://formulare.bawag.at/wizard/Forms/276498/sbtermin.html?userVariant='
      : 'https://formulare.bawag.at/wizard/Forms/276498/sbtermin.html?userVariant=';
  }

  private addAdobeAnalyticsHeader(host: string): void {
    const isProduction = !(
      host.includes('.tst') ||
      host.includes('.dev') ||
      host.includes('localhost')
    );
    let src1 = '';
    let src2 = '';
    let dataDomainScript = '';
    let pixel = '';
    if (isProduction) {

      src1 =
        'https://cdn.cookielaw.org/consent/0f47454c-5f13-4d5b-bd4a-ff53167ab03f/OtAutoBlock.js';
      src2 = 'https://cdn.cookielaw.org/consent/0f47454c-5f13-4d5b-bd4a-ff53167ab03f/otSDKStub.js'
      dataDomainScript = '0f47454c-5f13-4d5b-bd4a-ff53167ab03f';
      pixel = 'https://assets.adobedtm.com/c8f5f81f2a7a/eddbaf44e815/launch-46a9f7f77a5c.min.js';
    } else {
      src1 =
        'https://cdn.cookielaw.org/consent/0f47454c-5f13-4d5b-bd4a-ff53167ab03f-test/OtAutoBlock.js';
      src2 = 'https://cdn.cookielaw.org/consent/0f47454c-5f13-4d5b-bd4a-ff53167ab03f-test/otSDKStub.js'
      dataDomainScript = '0f47454c-5f13-4d5b-bd4a-ff53167ab03f-test';
      pixel =
        'https://assets.adobedtm.com/c8f5f81f2a7a/eddbaf44e815/launch-c407767271b0-development.min.js';
    }
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src1;
    document.head.appendChild(script);

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src2
    script.setAttribute('data-domain-script', dataDomainScript);
    document.head.appendChild(script);

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = 'function OptanonWrapper() { }';
    document.head.appendChild(script);

    script = document.createElement('script');
    script.src = pixel;
    script.async = true;
    script.setAttribute('class', 'optanon-category-C0002-C0003-C0004');
    script.type = 'text/plain';
    document.head.appendChild(script);
  }
}
