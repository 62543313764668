import { Component, Input, OnInit } from '@angular/core';
import { DataAccordionModel } from './data-accordion.model';

@Component({
  selector: 'app-data-accordion',
  templateUrl: './data-accordion.component.html',
  styleUrls: ['./data-accordion.component.scss']
})
export class DataAccordionComponent implements OnInit {

  @Input()
  data: DataAccordionModel[];

  isOpen = false;

  constructor() { }

  ngOnInit() {
  }

  closeAccordion() {
    this.isOpen = false;
  }

  openAccordion() {
    this.isOpen = true;
  }

  isAlwaysShows(i: number) {
    return i === 0 || i === this.data.length - 1
  }
}
