import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { select } from 'd3-selection';
import * as d3 from 'd3';
import { ChartModel } from '../../models/chart.models';

@Component({
  selector: 'app-summary-chart',
  templateUrl: './summary-chart.component.html',
  styleUrls: [ './summary-chart.component.scss' ]
})
export class SummaryChartComponent implements OnInit {
  el: HTMLElement;
  data: ChartModel[];

  @Input() set chartData(value: ChartModel[]) {
    this.data = value;
    this.drawHistogram(value);
  }

  constructor(private elementRef: ElementRef) {
    this.el = elementRef.nativeElement;
  }

  ngOnInit(): void {
  }

  getHummanableValue(n: number) {
    if (n > 10000000) {
      return `${Math.round(n / 1000000)}m`
    }
    if (n > 10000) {
      return `${Math.round(n / 1000)}k`;
    }
    return n;
  }

  drawHistogram(data: ChartModel[]) {

    function makeYgridlines() {
      return d3.axisLeft(y)
        .ticks(10)
    }

    d3.selectAll('svg#hist > *').remove();

    let hist = select(this.el).select('#hist');
    let margin = { top: 10, right: 30, bottom: 80, left: 40 };
    let width = parseInt(hist.style('width')) - margin.left - margin.right;
    let height = parseInt(hist.style('height')) - margin.top - margin.bottom;
    let g = hist
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    let svg = d3.select('#hist')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform',
        'translate(' + margin.left + ',' + margin.top + ')');

    const getMonthName = (currentDate: any, i: any) => {
      return `${currentDate.toLocaleString('de', { month: 'long'})} ${currentDate.getFullYear()}`
    };

    // Add X axis --> it is a date format
    let x = d3.scaleTime()
      .domain(d3.extent(data, function(d) { return d.date; }))
      .range([ 0, width ]);
    svg.append('g')
      .attr('transform', 'translate(0,' + (height + 5) + ')')
      .call(d3.axisBottom(x).ticks(12).tickFormat(getMonthName).tickSizeOuter(0))
      .selectAll('text')
      .attr('y', 5)
      .attr('x', 6)
      .attr('dy', '10px')
      .attr('transform', 'rotate(45)')
      .style('text-anchor', 'start');

    const zeroValues =  this.data.every((item) => { return item.value === 0; });
    // Add Y axis
    let y = d3.scaleLinear()
      .domain( zeroValues ? [0, 100, 200, 300] : [0, d3.max(data, function(d) {
        return d.value;
      }) * 2] )
      .range([ height, 0 ]);

    // add lines
    svg.append('g')
      .attr('class', 'grid')
      // .attr('color', '#f6f6f6')
      .call(makeYgridlines()
        .tickSize(-width)
        .tickFormat((d: any) => { return `€ ${this.getHummanableValue(d)}`; }));

    // Add the area

    svg.append('path')
      .datum(data)
      .attr('fill', 'var(--accent-color)')
      .attr('fill-opacity', 1)
      .attr('stroke', 'none')
      // @ts-ignore
      .attr('d', d3.area()
        .x(function(d: any) { return x(d.date) })
        .y0( height )
        .y1(function(d: any) { return y(d.value) })
      )

    // Add the line
    let gi = svg.selectAll(null)
      .data(data.slice(1, -1))
      .enter()
      .append('g');

    gi.append('circle')
      .attr('fill', '#ffffff')
      .attr('class', zeroValues ? 'empty circle': '')
      .attr('stroke', 'var(--accent-color)')
      .attr('cx', function(d: any) { return x(d.date) })
      .attr('cy', function(d: any) { return y(d.value) })
      .attr('r', 5)


    gi.append('text')
      .text((d: any) => { return `€ ${ this.getHummanableValue(Math.round(d.value * 100) / 100) } `; })
      .attr('class', (d, i) => {
        if (zeroValues) {
          return 'empty value-text';
        }
        return ((i === 0) || (i === data.length - 3)) ? 'highlight value-text' : 'value-text';
      })
      .attr('x', function(d: any) { return x(d.date) - 15 })
      .attr('y', function(d: any) { return y(d.value) - 15 })

  }
}
