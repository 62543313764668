import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input()
  isOpen = false;
  el: HTMLElement;
  isNotEnoughSpace = false;
  HINT_CONTAINER_WIDTH = 300;

  constructor(
    private eRef: ElementRef,
    public cdr: ChangeDetectorRef,
  ) {
    this.el = eRef.nativeElement;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.cdr.detectChanges();
    }
  }

  ngOnInit() {
  }

  toggleInfo() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.isNotEnoughSpace = this.isReverseNeeded();
    }
  }

  isReverseNeeded(): boolean {
    const appInfo = this.el;
    const infoContent = appInfo.querySelector('.info-content') as HTMLElement;
    let parentElement = appInfo.parentElement;
    let parentBlock;

    while (!parentBlock) {
      if (parentElement.classList.contains('block')) {
        parentBlock = parentElement;
      } else {
        parentElement = parentElement.parentElement;
      }
    }
    const blockWidth = parentBlock.offsetWidth;

    const appInfoOffsetLeft = appInfo.offsetLeft;
    const rightSpace = blockWidth - appInfoOffsetLeft;
    if (blockWidth < 500 && rightSpace < 300) {
      let leftOffset = appInfoOffsetLeft - 24 - 20;
      if (leftOffset > 294) {
        // set min length of container
        leftOffset = 294;
      }
      infoContent.style.left = `-${leftOffset}px`;
      return false;
    } else {
      // clean all styles
      infoContent.style.left = '';

      const globalParentPosition = parentBlock.getBoundingClientRect();
      const globalElementPosition = this.el.getBoundingClientRect();
      const relativeElementPosition = globalElementPosition.left - globalParentPosition.left;
      const deferral = blockWidth - relativeElementPosition;
      return deferral < this.HINT_CONTAINER_WIDTH;
    }
  }
}
