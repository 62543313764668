<div class="business-start-up-question">
  <div class="question-container">Ich habe mein Business in den letzten 12 Monaten gestartet.
    <app-info>
           <span class="info-content-span"> Für Start-ups im ersten Jahr 0€ Kontoführungsentgelt mit der Business Box Starter. </span>
    </app-info>
  </div>
  <div class="button-container">
    <div class="question-button"
         [ngClass]="{'active': isStartUp === startUp.yes }"
         (click)="setIsStartUp(startUp.yes)">
      Ja
    </div>
    <div class="question-button" (click)="setIsStartUp(startUp.no)"
         [ngClass]="{'active': isStartUp === startUp.no }">Nein
    </div>
  </div>
</div>
