<header>
  <div class="logo">
    <div class="container">
      <div class="logo-wrapper">
        <a href="https://www.bawag.at/bawag/businesskunden/sb">
          <img class="logo-img" src="/assets/svg/bawag-logo.svg">
        </a>
      </div>
    </div>
  </div>
</header>
<main>
  <div class="container">
    <h2 class="main-title">
      Jetzt persönliche Business-Kurve berechnen!
    </h2>

    <div class="main-description">
      <p>
        Haben Sie sich schon gefragt, wieviel ein Businesskonto kostet?<br/>
        Ideal, wenn sich die Kontoführung jeden Monat dem eigenen Business anpasst – egal, ob es gerade wächst oder
        saisonalen Schwankungen unterliegt. Die BusinessBox Dynamic bietet Ihnen genau diesen Vorteil – probieren Sie es
        doch gleich aus:
      </p>
    </div>


    <div class="block tab-block">
      <h3 class="block-title block__header row">
        <span class="block-header col-5 col-md-12">Mein Business ist …</span>

        <div class="tabs-navigation col-7 col-md-10">
          <div (click)="setActiveTab(tabList.stable)"
               [ngClass]="{'active': isTabActive(tabList.stable) }" class="navigation-button">
            stabil
          </div>
          <div (click)="setActiveTab(tabList.season)"
               [ngClass]="{'active': isTabActive(tabList.season) }" class="navigation-button navigation-button-middle">
            saisonal
          </div>
          <div (click)="setActiveTab(tabList.grow)"
               [ngClass]="{'active': isTabActive(tabList.grow) }" class="navigation-button">
            wachsend
          </div>
        </div>
      </h3>

      <div *ngIf="isTabActive(tabList.stable)" class="stable-details">
        <div class="row ai-b">
          <app-company-age [isStartUp]="isStartUp"
                           (companyAgeChanged)="onCompanyAgeChanged($event)"></app-company-age>
        </div>
      </div>

      <div *ngIf="isTabActive(tabList.season)" class="season-details">
        <div class="row ai-b">
          <app-company-age [isStartUp]="isStartUp"
                           (companyAgeChanged)="onCompanyAgeChanged($event)"></app-company-age>
        </div>
      </div>
      <div *ngIf="isTabActive(tabList.grow)" class="grow-details">
        <div class="row ai-b">
          <div class="col-11 col-md-9 percent-text-info">
            Im Jahr wächst mein Geschäft um …
          </div>
          <div class="col-1 col-md-3 percent-input-container">
            <div class="percent-input-wrapper ml-a">
              <input type="text" #growPercentInput [ngModel]="growPercentValue"
                     (ngModelChange)="onGrowPercentValueChange($event)"
                     class="percent-input"><span>%</span>
            </div>
          </div>
        </div>
        <div class="row mb">
          <div class="col-5"></div>
          <div class="col-7 grow-range-container">
            <app-range
              (valueChange)="onGrowPercentValueChange($event)"
              [value]="growPercentValue"></app-range>
          </div>
        </div>
        <div class="row ai-b">
          <app-company-age [isStartUp]="isStartUp" (companyAgeChanged)="onCompanyAgeChanged($event)"></app-company-age>
        </div>
      </div>
    </div>

    <div class="block stable-tab" *ngIf="isTabActive(tabList.stable) && isCompanyAgeSelected()">
      <h3 class="block-title block-header">
        Meine Kontoaktivitäten
      </h3>

      <div class="row">
        <div class="col-6">
          <div class="calc-number left-calc-number">
            <div class="cal-number-title">
              Anzahl der elektronischen Transaktionen im Monat
              <app-info>
                  <span>
                    Beispiele für elektronische Transaktionen sind:
                  </span>
                <ul class="list-container">
                  <li>elektronische Gutschriften</li>
                  <li>Onlineüberweisungen</li>
                  <li>SEPA-Lastschriften</li>
                  <li>Daueraufträge</li>
                  <li>Datenträgerumsätze</li>
                  <li>etc…</li>
                </ul>
              </app-info>
            </div>

            <app-input-number
              [min]="minTransactionCount"
              [max]="maxTransactionCount"
              [step]="5"
              (valueChanged)="onTransactionCountChanged($event)"
              [value]="transactionCount"></app-input-number>
          </div>
        </div>
        <div class="col-6">
          <div class="calc-number right-calc-number">
            <div class="cal-number-title">
              Höhe des Kontoumsatzes im Monat
            </div>

            <app-input-number
              [isMoney]="true"
              [min]="minRevenueCount"
              [max]="maxRevenueCount"
              [stepFn]="getRevenueStep"
              (valueChanged)="onRevenueChanged($event)"
              [value]="revenueCount"></app-input-number>
          </div>
        </div>
      </div>
    </div>

    <div class="block season-tab" *ngIf="isTabActive(tabList.season) && isCompanyAgeSelected()">
      <div class="row">
        <div class="col-6">
          <h3 class="block-title">
            <span class="block-header">Meine Kontoaktivitäten</span>

            <div class="block-title-hint">
              in der Nebensaison
            </div>
          </h3>

          <div class="row">

            <div class="calc-number left-calc-number">
              <div class="cal-number-title">
                Anzahl der elektronischen Transaktionen im Monat
                <app-info>
                  <span>
                    Beispiele für elektronische Transaktionen sind:
                  </span>
                  <ul class="list-container">
                    <li>elektronische Gutschriften</li>
                    <li>Onlineüberweisungen</li>
                    <li>SEPA-Lastschriften</li>
                    <li>Daueraufträge</li>
                    <li>Datenträgerumsätze</li>
                    <li>etc…</li>
                  </ul>
                </app-info>
              </div>

              <app-input-number
                [min]="minTransactionCount"
                [max]="maxTransactionCount"
                [step]="5"
                [value]="seasonLowTransactionNumber"
                (valueChanged)="onSeasonLowTransactionNumberChanged($event)"></app-input-number>
            </div>

            <div class="calc-number left-calc-number">
              <div class="cal-number-title">
                Höhe des Kontoumsatzes im Monat
              </div>

              <app-input-number
                [min]="minRevenueCount"
                [max]="maxRevenueCount"
                [isMoney]="true"
                [stepFn]="getRevenueStep"
                (valueChanged)="onSeasonLowRevenueNumberChanged($event)"
                [value]="seasonLowRevenueNumber"></app-input-number>
            </div>
          </div>
        </div>

        <div class="col-6 alternative-bg">
          <h3 class="block-title">
            <div class="block-title-hint">
              in der Hochsaison
            </div>
          </h3>

          <div class="row">

            <div class="calc-number right-calc-number">
              <div class="cal-number-title">
                Anzahl der elektronischen Transaktionen im Monat
                <app-info>
                  <span>
                    Beispiele für elektronische Transaktionen sind:
                  </span>
                  <ul class="list-container">
                    <li>elektronische Gutschriften</li>
                    <li>Onlineüberweisungen</li>
                    <li>SEPA-Lastschriften</li>
                    <li>Daueraufträge</li>
                    <li>Datenträgerumsätze</li>
                    <li>etc…</li>
                  </ul>
                </app-info>
              </div>

              <app-input-number
                [min]="minTransactionCount"
                [max]="maxTransactionCount"
                [step]="5"
                (valueChanged)="onSeasonHighTransactionNumberChanged($event)"
                [value]="transactionCount"></app-input-number>
            </div>

            <div class="calc-number right-calc-number">
              <div class="cal-number-title">
                Höhe des Kontoumsatzes im Monat
              </div>

              <app-input-number
                [min]="minRevenueCount"
                [max]="maxRevenueCount"
                [isMoney]="true"
                [stepFn]="getRevenueStep"
                (valueChanged)="onSeasonHighRevenueNumberChanged($event)"
                [value]="revenueCount"></app-input-number>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="block grow-tab" *ngIf="isTabActive(tabList.grow) && isCompanyAgeSelected()">
      <h3 class="block-title block-header">
        Meine Kontoaktivitäten
      </h3>

      <div class="row">
        <div class="col-6">
          <div class="calc-number left-calc-number">
            <div class="cal-number-title">
              Anzahl der elektronischen Transaktionen im Monat
              <app-info>
                  <span>
                    Beispiele für elektronische Transaktionen sind:
                  </span>
                <ul class="list-container">
                  <li>elektronische Gutschriften</li>
                  <li>Onlineüberweisungen</li>
                  <li>SEPA-Lastschriften</li>
                  <li>Daueraufträge</li>
                  <li>Datenträgerumsätze</li>
                  <li>etc…</li>
                </ul>
              </app-info>
            </div>

            <app-input-number
              [min]="minTransactionCount"
              [max]="maxTransactionCount"
              [step]="5"
              (valueChanged)="onGrowTransactionNumberChanged($event)"
              [value]="transactionCount"></app-input-number>
          </div>
        </div>
        <div class="col-6">
          <div class="calc-number right-calc-number">
            <div class="cal-number-title">
              Höhe des Kontoumsatzes im Monat
            </div>

            <app-input-number
              [min]="minRevenueCount"
              [max]="maxRevenueCount"
              [stepFn]="getRevenueStep"
              (valueChanged)="onGrowRevenueNumberChanged($event)"
              [isMoney]="true"
              [value]="revenueCount"></app-input-number>
          </div>
        </div>
      </div>
    </div>

    <div class="block summary-info" *ngIf="!!activeTab && isCompanyAgeSelected()">
      <div class="block-title block-header" [ngClass]="{'dynamic' : isStartUp === startUp.no}">
        Mein Kontoentgelt auf einen Blick

        <div class="summary-info-block" *ngIf="isStartUp === startUp.yes">
          mit der BusinessBox Starter
        </div>
        <div class="summary-info-block" *ngIf="isStartUp === startUp.no">
          mit der BusinessBox Dynamic
        </div>
      </div>

      <div class="summary-data stable-summary row" *ngIf="isTabActive(tabList.stable)">
        <div class="data-item col-4 col-md-12">
          <div class="data-item-value monthly-item">
            € {{ getFeeValue() | number:'0.2-2':'de'  }}
          </div>
          <div class="data-item-hint">
            monatlich
          </div>
        </div>
        <div class="data-item col-4 col-md-6">
          <div class="data-item-value quoter-item">
            € {{ getFeeValue() * 3 | number:'0.2-2':'de'  }}
          </div>
          <div class="data-item-hint">
            im Quartal
          </div>
        </div>
        <div class="data-item col-4 col-md-6">
          <div class="data-item-value yearly-item">
            € {{ getFeeValue() * 12 | number:'0.2-2':'de'  }}
          </div>
          <div class="data-item-hint">
            jährlich
          </div>
        </div>
      </div>
      <div class="summary-data season-summary" *ngIf="isTabActive(tabList.season)">
        <div class="data-item">
          <div class="data-item-header">
            in der Nebensaison
          </div>
          <div class="data-item-value">
            € {{ getSeasonLowFee() | number:'0.2-2':'de'}}
          </div>
          <div class="data-item-hint">
            monatlich
          </div>
        </div>
        <div class="data-item alternative-bg">
          <div class="data-item-header">
            in der Hochsaison
          </div>
          <div class="data-item-value">
            € {{ getSeasonHighFee() | number:'0.2-2':'de'}}
          </div>
          <div class="data-item-hint">
            monatlich
          </div>
        </div>
      </div>
      <div class="summary-data grow-summary" *ngIf="isTabActive(tabList.grow)">
        <app-summary-chart *ngIf="!isMobile()"
          [chartData]="chartData"
        ></app-summary-chart>
        <app-data-accordion *ngIf="isMobile()" [data]="accordionData"></app-data-accordion>
      </div>

      <div class="block-summary-footer footer">
        <div class="summary-footer-text">
          Kosten berücksichtigen elektronische Transaktionen, Manipulationsentgelt auf Umsätze und € 9,90
          Kontoführungsentgelt im Monat.
          Im Eröffnungsmonat € 0 Kontoführungsentgelt. Für Start-ups im gesamten ersten Jahr 0€ Kontoführungsentgelt.
          Weitere Entgelte entnehmen Sie der
          <a target="_blank" href="https://www.bawag.at/resource/blob/19326/b6271473b582b4d743da4a2342ce5f2f/preisblatt-starter-und-dynamic-data.pdf"
             class="summary-footer-text-link">Konditionenübersicht</a>.
        </div>
      </div>
    </div>

    <div class="button-container" *ngIf="!!activeTab && isCompanyAgeSelected()">
      <div class="button-block">
        <div class="list-title">
          Ihr Unternehmen ist:
        </div>
        <ul class="description-list">
          <li>
            Nicht eingetragenes Einzelunternehmen
          </li>
          <li>
            Eingetragenes Einzelunternehmen (als Neukunde)
          </li>
          <li>
            GmbH (als Neukunde)
          </li>
        </ul>
        <a href="https://businesskonto.bawag.at/"
           class="button-finish">
          Jetzt online beantragen
        </a>
      </div>

      <div class="button-block">
        <div class="list-title">
          Ihr Unternehmen ist:
        </div>
        <ul class="description-list">
          <li>
            Eine andere Rechtsform (z.B. KG, OG, AG, GesbR etc.)
          </li>
          <li>
            Eingetragenes Einzelunternehmen (als Bestandskunde)
          </li>
          <li>
            GmbH (als Bestandskunde)
          </li>
        </ul>
        <a [attr.href]="getNavigationLink()"
           class="button-finish alternative-button">
          Jetzt Termin vereinbaren
        </a>
      </div>
    </div>
  </div>
</main>
<footer class="footer" *ngIf="!!activeTab">
  <div class="container">
    <div class="footer-container">
      <div class="footer__left-container">
        <a href="mailto:kundenservice@bawag.at">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fill-rule="evenodd">
              <path d="M0 0H20V20H0z"/>
              <path fill="#999"
                    d="M2.46 14.749V5.463l7.273 6.643c.075.069.171.103.267.103s.192-.034.268-.103l7.271-6.643v9.286H2.46zm14.454-9.788L10 11.275 3.086 4.96h13.828zm1.408-.455c-.004-.029-.007-.057-.017-.085-.011-.028-.029-.051-.046-.076-.011-.016-.016-.034-.03-.048-.001-.003-.005-.004-.006-.006-.022-.022-.048-.036-.073-.053-.018-.012-.035-.027-.054-.037-.018-.008-.039-.009-.059-.014-.03-.008-.06-.018-.092-.018l-.008-.002H2.064l-.01.002c-.03 0-.058.01-.088.018-.02.005-.043.006-.062.014-.019.009-.034.024-.051.036-.027.016-.053.031-.075.054l-.008.005c-.013.015-.018.034-.029.05-.017.024-.035.047-.046.075-.01.028-.012.056-.016.085-.004.02-.012.037-.012.058v10.582c0 .219.177.397.397.397h15.873c.218 0 .397-.178.397-.397V4.564c0-.02-.01-.037-.012-.058z"/>
            </g>
          </svg>
          kundenservice@bawag.at
        </a>

        <a href="tel:05 99 05 995">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fill-rule="evenodd">
              <path d="M0 0H20V20H0z"/>
              <path fill="#999"
                    d="M13.898 16.716c-1.84 0-4.297-1.402-6.74-3.844C4.7 10.413 3.29 7.948 3.29 6.108c0-1.896 1.53-2.735 2.056-2.81.036-.005.07-.007.102-.007.281 0 .431.188.632.483l1.65 2.522c.214.336.126.778-.226 1.026l-.082.068c-.364.296-.861.702-.897 1.417-.04.797.51 1.739 1.734 2.963 1.218 1.217 2.145 1.767 2.941 1.724.71-.037 1.11-.533 1.404-.895l.1-.122c.228-.327.67-.415 1.001-.205l2.462 1.609c.29.202.575.57.541.8-.054.359-.958 2.035-2.81 2.035m2.714-3.487l-.009-.006-2.47-1.615c-.695-.439-1.605-.256-2.057.39l-.085.104c-.287.354-.498.586-.833.604-.362.016-1.07-.224-2.34-1.494C7.54 9.933 7.294 9.22 7.313 8.848c.017-.34.252-.556.608-.846l.061-.053c.672-.47.855-1.382.413-2.08L6.783 3.401c-.003-.002-.048-.069-.05-.071-.223-.328-.636-.94-1.499-.814C4.281 2.652 2.5 3.846 2.5 6.108c0 2.804 2.57 5.791 4.1 7.322 2.59 2.59 5.25 4.075 7.298 4.075 2.389 0 3.505-2.134 3.591-2.707.103-.687-.503-1.31-.877-1.569"/>
            </g>
          </svg>
          05 99 05 995</a>
      </div>
      <div class="footer__right-container">
        <a target="_blank" href="https://www.bawag.at/bawag/impressum">Impressum</a>
        <span>&copy;BAWAG</span>
        <span>v.1.0.0</span>
      </div>
    </div>
  </div>
</footer>
