import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { first } from 'lodash';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: [ './input-number.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class InputNumberComponent implements OnInit {
  @Input() set value(value: number) {
    this._value = value;
    this.numberValidation();
  }

  _value: number;

  @Input()
  isMoney = false;

  @Input()
  step = 1;

  @Input()
  min: number = 0;

  @Input()
  max: number;

  @Input()
  set stepFn(value)  {
    if (value) {
      this._stepFn = value;
      return;
    }

    this._stepFn = (value) => {
      return this.step;
    }
  }

  _stepFn = (value) => {
    return this.step;
  };

  @Output()
  valueChanged = new EventEmitter<number>();

  @ViewChild('inputElement') inputElement: ElementRef;

  private stepInterval: number = 0;
  private stepSpeed = 200;
  constructor(
    private decimalPipe: DecimalPipe,
  ) {

  }

  ngOnInit() {
  }

  onValueChange($event) {
    const values = $event.split(String.fromCharCode(160));
    const value = first(values) as string;
    this._value = parseFloat(value.replace(/\./g, '').replace(/,/g, '.'));
    if (this.numberValidation()) {
      this.inputElement.nativeElement.value = this._value;
    }
    this.valueChanged.emit(this._value);
    this.inputElement.nativeElement.value =
      this.isMoney ?
        this.decimalPipe.transform(this._value, '1.0', 'de')
        : this._value;
  }

  numberValidation() {
    if (Number.isNaN(this._value)) {
      this._value = 0;
      return true;
    }

    if (this._value > this.max) {
      this._value = this.max;
      this.stopIncrease();
      return true;
    }

    if (this._value < this.min) {
      this._value = this.min;
      this.stopDecrease();
      return true;
    }

    return false;
  }

  increase(event) {
    if(event.which !== 3) {
      this.stepInterval = setInterval(() => {
        this._value += this._stepFn(this._value);
        this.onValueChange(this._value.toString());
      }, this.stepSpeed);
      this._value += this._stepFn(this._value);
      this.onValueChange(this._value.toString());
    }
  }

  decrease(event) {
    if(event.which !== 3) {
      this.stepInterval = setInterval(() => {
        this._value -= this._stepFn(this._value);
        this.onValueChange(this._value.toString());
      }, this.stepSpeed);
      this._value -= this._stepFn(this._value);
      this.onValueChange(this._value.toString());
    }
  }

  stopIncrease() {
    clearInterval(this.stepInterval);
  }

  stopDecrease() {
    clearInterval(this.stepInterval);
  }

  isMinimalValue() {
    return this._value === this.min;
  }

  isMaximumValue() {
    return this._value === this.max;
  }
}
