export enum TabList {
  stable = 'stable',
  season = 'season',
  grow = 'grow'
}

export enum StartUP {
  yes = 'Ja',
  no = 'Nien'
}
